html,
body,
#root {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
